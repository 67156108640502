<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="会员编号:" prop="MemberCode">
              <Input
                placeholder="请输入会员编号"
                v-model="params.MemberCode"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="会员名称:" prop="MemberName">
              <Input
                placeholder="请输入会员名称"
                v-model="params.MemberName"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="手机号码:" prop="Mobile">
              <Input
                placeholder="请输入会员手机号"
                v-model="params.Mobile"
                clearable
              />
            </FormItem>
          </Col>
          <!--
          <Col span="6">
            <FormItem label="身份证号:" prop="IdentityCard">
              <Input
                placeholder="请输入身份证号码"
                v-model="params.IdentityCard"
                clearable
              />
            </FormItem>
          </Col> -->
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left"></Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="memberTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      @on-sort-change="onSortChange"
      size="small"
    >
      <template slot="State" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.State === 0
              ? 'success'
              : row.State === 1
              ? 'volcano'
              : row.State === 2
              ? 'magenta'
              : row.State === 3
              ? 'red'
              : row.State === 4
              ? 'warning'
              : 'default'
          "
        >
          {{ row.MemberStateCN }}
        </Tag>
      </template>

      <template slot="ReferrerCode" slot-scope="{ row }">
        <span
          style="color: #19be6b; cursor: pointer"
          @click="handleShowReferrerUser(row.ReferrerCode)"
        >
          {{ row.ReferrerCode }}
        </span>
      </template>

      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="info"
          v-if="$canAction(['Member_Audit'])"
          @click="handleOpenAudit(row)"
        >
          审核
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <!--    用户信息展示抽屉-->
    <member-drawer ref="memberDrawer" />
    <audit-modal ref="userAuditModal" />
  </Layout>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import Layout from "@/components/layout/index.vue";
import MemberDrawer from "./components/member-drawer.vue";
import AuditModal from "./components/audit-modal.vue";
export default {
  name: "MemberAudit",
  data() {
    return {
      tableLoading: false,
      tableHeight: 0,
      params: {
        MemberCode: "",
        MemberName: "",
        MobilePhone: "",
        IdentityCard: "",
        Status: 10,
        Page: 1,
        PageSize: 40,
        SortName: "CreateDate",
        SortOrder: "Desc",
      },
      columns: [
        {
          title: "会员编号",
          key: "MemberCode",
          minWidth: 90,
          sortable: "custom",
        },
        { title: "会员名称", key: "MemberName", minWidth: 120 },
        { title: "会员身份", key: "MemberIdentityCN", minWidth: 95 },
        {
          title: "会员等级",
          key: "MemberLevelID",
          minWidth: 95,
          sortable: "custom",
        },
        { title: "手机号", key: "Mobile", minWidth: 95 },
        {
          title: "推荐人编号",
          key: "ReferrerCode",
          slot: "ReferrerCode",
          minWidth: 90,
        },
        {
          title: "提交时间",
          key: "CreateDate",
          align: "center",
          minWidth: 135,
          sortable: "custom",
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      showUserProfileDrawer: false,
    };
  },
  computed: {
    memberStateEnum() {
      return bizEnum.MemberStatusEnum;
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        fixed: "right",
        width: this.$route.meta.tableActionWidth,
      });
    }
    this.loadMemberList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.addEventListener("keydown", this.keydownFun);
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params = this.$options.data().params;
        this.loadMemberList();
      }
    });
  },
  activated() {
    document.addEventListener("keydown", this.keydownFun);
  },
  deactivated() {
    document.removeEventListener("keydown", this.keydownFun);
  },
  methods: {
    keydownFun() {
      const self = this;
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadMemberList();
      }
    },
    onSortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = result.order !== "normal" ? result.order : "desc";
      this.loadMemberList();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadMemberList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadMemberList();
    },
    async loadMemberList() {
      this.tableLoading = true;
      try {
        const res = await api.GetMemberAuditList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadMemberList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadMemberList();
    },
    async handleShowReferrerUser(code) {
      try {
        const res = await api.GetMemberByCode({
          code: code,
        });
        this.$refs.memberDrawer.obj = JSON.parse(JSON.stringify(res.Data)); // 去掉双向数据绑定
        this.$refs.memberDrawer.isShow = true;
      } catch (error) {
        console.error(error);
      }
    },
    handleOpenAudit(row) {
      this.$refs.userAuditModal.obj = JSON.parse(JSON.stringify(row));
      this.$refs.userAuditModal.isShow = true;
    },
  },
  components: { MemberDrawer, AuditModal, Layout },
};
</script>

<style lang="less"></style>
