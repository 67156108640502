<template>
  <Modal
    class-name="i-modal"
    class="member_audit"
    title="审核会员"
    v-model="isShow"
    width="600"
    loading
    :maskClosable="false"
  >
    <Row :gutter="24">
      <Col span="24">
        <!-- <div v-if="obj.MemberIdentity == 10">
          <Divider orientation="center">会员信息</Divider>
          <Row class="text-cell">
            <Col class="title" span="4">会员编号：</Col>
            <Col class="value" span="8">{{ obj.MemberCode }}</Col>
            <Col class="title" span="4">会员名称：</Col>
            <Col class="value" span="8">{{ obj.MemberName }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">推荐编码：</Col>
            <Col class="value" span="8">{{ obj.ReferrerCode }}</Col>
            <Col class="title" span="4">手机号码：</Col>
            <Col class="value" span="8">{{ obj.Mobile }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">汇款金额</Col>
            <Col class="value" span="8">{{ obj.PayAmount }}</Col>
            <Col class="title" span="4">公司名：</Col>
            <Col class="value" span="8">{{ obj.MemberCompany.Name }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">公司代码：</Col>
            <Col class="value" span="8">{{ obj.MemberCompany.CreditCode }}</Col>
            <Col class="title" span="4">公司类型：</Col>
            <Col class="value" span="8">{{ obj.MemberCompany.Type }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">开户银行：</Col>
            <Col class="value" span="8">{{ obj.MemberCompany.BankName }}</Col>
            <Col class="title" span="4">银行账号：</Col>
            <Col class="value" span="8">{{
              obj.MemberCompany.BankAccount
            }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">证件起始时间：</Col>
            <Col class="value" span="8">{{ obj.MemberCompany.BeginDate }}</Col>
            <Col class="title" span="4">证件结束时间：</Col>
            <Col class="value" span="8">{{ obj.MemberCompany.EndDate }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">备注：</Col>
            <Col class="value" span="8">{{ obj.Remark }}</Col>
            <Col class="title" span="4">公司地址：</Col>
            <Col class="value" span="8">{{ obj.MemberCompany.Address }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">审核意见：</Col>
            <Col span="20">
              <Input
                type="textarea"
                :rows="3"
                placeholder="请填写审核意见"
                v-model="obj.AuditOpinion"
              ></Input>
            </Col>
          </Row>
        </div> -->
        <div v-if="obj.MemberIdentity == 20">
          <Divider orientation="center">会员信息</Divider>
          <Row class="text-cell">
            <Col class="title" span="4">会员编号：</Col>
            <Col class="value" span="8">{{ obj.MemberCode }}</Col>
            <Col class="title" span="4">会员名称：</Col>
            <Col class="value" span="8">{{ obj.MemberName }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">真实姓名：</Col>
            <Col class="value" span="8">{{ obj.RealName }}</Col>
            <Col class="title" span="4">手机号码：</Col>
            <Col class="value" span="8">{{ obj.Mobile }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">会员身份：</Col>
            <Col class="value" span="8">{{ obj.MemberIdentityCN }}</Col>
            <Col class="title" span="4">推荐编码：</Col>
            <Col class="value" span="8">{{ obj.ReferrerCode }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">提交时间：</Col>
            <Col class="value" span="8">{{ obj.CreateDate }}</Col>
            <Col class="title" span="4">备注：</Col>
            <Col class="value" span="8">{{ obj.Remark }}</Col>
          </Row>
          <Row class="text-cell">
            <Col class="title" span="4">审核意见：</Col>
            <Col span="20">
              <Input
                type="textarea"
                :rows="5"
                placeholder="请填写审核意见"
                v-model="obj.AuditOpinion"
              ></Input>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
    <div slot="footer">
      <Button type="default" @click="isShow = false">取消</Button>
      <Button type="error" :loading="asyncLoading" @click="handleOnAction(30)"
        >拒绝</Button
      >
      <Button type="success" :loading="asyncLoading" @click="handleOnAction(20)"
        >通过</Button
      >
    </div>
    <Modal
      title="查看大图"
      width="600"
      draggable
      scrollable
      class="i-modal-draggable"
      v-model="isShowViewImage"
      :footer-hide="true"
    >
      <img :src="ViewImg" v-if="isShowViewImage" style="width: 100%" />
      <template slot="footer">
        <Button @click="isShowViewImage = false">关闭</Button>
      </template>
    </Modal>
  </Modal>
</template>

<script>
import api from "@/api";

export default {
  name: "audit-modal",
  data() {
    return {
      isShow: false,
      obj: {},
      asyncLoading: false,
      ViewImg: "",
      isShowViewImage: false,
    };
  },
  methods: {
    handleViewImg(src) {
      this.ViewImg = src;
      this.isShowViewImage = true;
    },
    async handleOnAction(state) {
      if (
        state === 30 &&
        (!this.obj.AuditOpinion || this.obj.AuditOpinion === "")
      ) {
        return this.$Message.error("请填写审核意见");
      }
      this.asyncLoading = true;
      this.obj.Status = state;
      try {
        const res = await api.AuditMember({
          memberAudit: this.obj,
        });
        this.asyncLoading = false;
        if (res.Status === 100) {
          const resObj = this.$parent.$parent.resObj;
          this.isShow = false;
          let index = resObj.DataList.findIndex(
            (item) => item.ID === this.obj.ID
          );
          if (index !== -1) {
            if (index === resObj.DataList.length - 1) {
              this.$Message.success("本页会员已审核完成");
              this.$parent.$parent.resObj.DataList.splice(index, 1);
              this.isShow = false;
            } else {
              this.$Message.success("审核成功！");
              this.obj = resObj.DataList[index + 1];
              this.$parent.$parent.resObj.DataList.splice(index, 1);
            }
          }
        } else {
          this.$Notice.error({
            title: "错误",
            desc: res.Msg,
          });
        }
      } catch (error) {
        this.asyncLoading = false;
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.member_audit {
  .img {
    width: 470px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    & > img {
      width: 100%;
      height: auto;
    }
  }
  .img2 {
    width: 470px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    & > img2 {
      width: 100%;
      height: auto;
    }
  }
}
.i-modal-draggable {
  .ivu-modal {
    top: calc(50% - 330px);
  }
  .ivu-modal-content-drag {
    right: 50%;
    height: 660px;
  }
  .ivu-modal-body {
    height: calc(100% - 50px);
    overflow-y: scroll;
  }
}
</style>
