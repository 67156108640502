<template>
  <Drawer v-model="isShow" width="600" scrollable draggable>
    <Row class="text-cell">
      <i-col class="title" span="4">头像：</i-col>
      <i-col span="8">
        <Avatar
          @click.native="handleViewImg($setImagePrefix(obj.Avatar))"
          :src="$setImagePrefix(obj.Avatar)"
        ></Avatar>
      </i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">会员编号：</i-col>
      <i-col class="value" span="8">{{ obj.MemberCode }}</i-col>
      <i-col class="title" span="4">会员昵称：</i-col>
      <i-col class="value" span="8">{{ obj.MemberName }}</i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">会员姓名：</i-col>
      <i-col class="value" span="8">{{ obj.RealName }}</i-col>
      <i-col class="title" span="4">性别：</i-col>
      <i-col class="value" span="8">{{
        obj.Sex === null
          ? ""
          : obj.Sex === 1
          ? "男"
          : obj.Sex === 0
          ? "女"
          : "未知"
      }}</i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">手机号：</i-col>
      <i-col class="value" span="8">{{ obj.Mobile }}</i-col>
      <i-col class="title" span="4">身份证号：</i-col>
      <i-col class="value" span="8">{{ obj.IdentityCard }}</i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">省市地区：</i-col>
      <i-col class="value district-show" span="8">
        <Cascader
          :data="addressList || []"
          trigger="hover"
          filterable
          disabled
          v-model="addressArr"
        />
      </i-col>
    </Row>
    <!-- <Row class="text-cell">
      <i-col class="title"
             span="4">所属花型</i-col>
      <i-col class="value"
             span="8">{{ obj.FlowerTypeCN }}</i-col>
    </Row> -->
    <Divider orientation="left">账号信息</Divider>
    <Row class="text-cell">
      <i-col class="title" span="4">一级代理</i-col>
      <i-col class="value" span="8">{{ obj.MemberOne }}</i-col>
      <i-col class="title" span="4">二级代理</i-col>
      <i-col class="value" span="8">{{ obj.MemberTwo }}</i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">团队编号：</i-col>
      <i-col class="value" span="8">{{ obj.TeamID }}</i-col>
      <i-col class="title" span="4">注册方式：</i-col>
      <i-col class="value" span="8">{{
        obj.RegType === 10 ? "微信小程序" : "未知"
      }}</i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">会员状态：</i-col>
      <i-col span="8">
        <Tag
          type="border"
          :color="
            obj.Status === 30
              ? 'green'
              : obj.Status === 10
              ? 'volcano'
              : obj.Status === 20
              ? 'magenta'
              : obj.Status === 40
              ? 'red'
              : obj.Status === 50
              ? 'orange'
              : 'default'
          "
        >
          {{ obj.MemberStatusCN }}</Tag
        >
      </i-col>
      <i-col class="title" span="4">注册渠道：</i-col>
      <i-col class="value" span="8">{{
        obj.Channel === 10 ? "小程序" : "未知"
      }}</i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">会员等级：</i-col>
      <i-col class="value" span="8">{{ obj.MemberLevelID }}</i-col>
      <i-col class="title" span="4">会员类型：</i-col>
      <i-col class="value" span="8">{{ obj.MemberIdentityCN }} </i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">购买总金额：</i-col>
      <i-col class="value" span="8">{{ obj.TotalAmount }}</i-col>
      <i-col class="title" span="4">推荐人数：</i-col>
      <i-col class="value" span="8">{{ obj.PartnerNum }}</i-col>
    </Row>
    <Row class="text-cell">
      <i-col class="title" span="4">测试账号：</i-col>
      <i-col class="value" span="8">{{
        obj.IsTest === null ? "" : obj.IsTest ? "是" : "否"
      }}</i-col>
      <i-col class="title" span="4">设备类型：</i-col>
      <i-col class="value ellipsis" span="8">{{ obj.DeviceType }}</i-col>
    </Row>

    <Modal
      title="查看大图"
      class-name="i-modal"
      width="400"
      v-model="isShowViewImage"
      :footer-hide="true"
    >
      <img :src="ViewImg" v-if="isShowViewImage" style="width: 100%" />
      <template slot="footer">
        <Button @click="isShowViewImage = false">关闭</Button>
      </template>
    </Modal>
  </Drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "member-detail-drawer",
  data() {
    return {
      isShowViewImage: false,
      downView: false,
      isShow: false,
      loading: false,
      obj: {},
      objDown: {},
      addressArr: [],
      ViewImg: "",
      validateRules: {
        LevelName: [{ required: true, message: "等级名称不能为空" }],
      },
    };
  },
  methods: {
    handleViewImg(src) {
      this.ViewImg = src;
      this.isShowViewImage = true;
    },
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.addressArr = [
          this.obj.Province.toString(),
          this.obj.City.toString(),
          this.obj.District.toString(),
        ];
      }
    },
  },
  computed: {
    ...mapGetters(["addressList"]),
  },
};
</script>

<style lang="less">
.district-show .ivu-cascader-arrow {
  display: none;
}
</style>
